<template>
  <div>
    <div class="title">{{ $t('acknowledgementOrder') }}</div>
    <el-steps class="progress" :active="active">
      <el-step :title="$t('confirmationInformation')"></el-step>
      <el-step :title="$t('confirmContract')"></el-step>
      <el-step :title="$t('confirmPayment')"></el-step>
    </el-steps>
    <div v-if="active === 0">
      <div class="info">{{ $t('projectInformation') }}</div>
      <div class="title-view" style="border:0;">
        <div v-for="(item, index) in proInfo.title" :key="index">
          {{ item.title }}
        </div>
      </div>
      <div class="info-view">
        <div v-for="(item, index) in proInfo.info" :key="index">
          {{ item.info }}
        </div>
      </div>
    </div>
    <div v-if="active === 1">
      <div>
        {{ $t('forYou') }}
        <span style="color:#FF0101">{{ $t('agree') }}</span>
      </div>
      <div class="flex-column-center agree">
        <div>{{ $t('oneAgreement') }}</div>
        <div>{{ $t('onePart') }}</div>
      </div>
      <div>
        <el-checkbox class="box-agree" v-model="checked"></el-checkbox>
        <span>{{ $t('readAgree') }}</span>
        <span class="text-red">{{ $t('Investment') }}</span>
        <span style="color:#FF0101">{{ $t('ContentAgreement') }}</span>
      </div>
    </div>
    <div v-if="active === 2">
      <div class="flex-between agree">
        <div>{{ $t('Account') }}(207.5{{ $t('dollar') }})</div>
        <div style="color:#81D8D0">
          {{ $t('payment') }}(100.0{{ $t('dollar') }})
        </div>
      </div>
    </div>
    <div v-if="active === 3">
      <div class="flex-column-center success">
        <i style="font-size: 30px;" class="el-icon-circle-check"></i>
        <div>{{ $t('SuccessfulInvestment') }}</div>
      </div>
    </div>
    <el-button class="btn-black next" @click="next">{{ btnText }}</el-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: 0,
      // proInfo: [{ title: this.$t('entryName'), info: this.$t('companyNameLT') },
      // { title: this.$t('revenueDate'), info: '2020.08.01' },
      // { title: this.$t('annualizedIncomeAgain'), info: '8%-10%' },
      // { title: this.$t('repaymentDateAgainS'), info: '2020.12.01' },
      // { title: this.$t('InvestmentAmount'), info: '100,000,000.0' },
      // { title: this.$t('ExpectedRevenue'), info: '1,000,000.0' }],
      proInfo: {
        title: [
          { title: this.$t('entryName') },
          { title: this.$t('revenueDate') },
          { title: this.$t('annualizedIncomeAgain') },
          { title: this.$t('repaymentDateAgainS') },
          { title: this.$t('InvestmentAmount') },
          { title: this.$t('ExpectedRevenue') }
        ],
        info: [
          { info: this.$t('companyNameLT') },
          { info: '2020.08.01' },
          { info: '8%-10%' },
          { info: '2020.12.01' },
          { info: '100,000,000.0' },
          { info: '1,000,000.0' }
        ]
      },
      checked: false,
      btnText: this.$t('nextStep')
    };
  },
  methods: {
    next() {
      this.active++;
      switch (this.active) {
        case 2:
          this.btnText = this.$t('complete');
          break;
        case 3:
          this.btnText = this.$t('ViewInvestments');
          break;
        case 4:
          this.$router.replace({ path: '/my-invest' });
          break;
        default:
          break;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.progress {
  margin-bottom: 50px;
  ::v-deep .is-finish {
    color: #81d8d0;
    border-color: #81d8d0;
  }
}
.info {
  border-bottom: 1px solid #979797;
  padding: 4px 0;
}
.title-view,
.info-view {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  border: 0;
  padding: 0;
  div {
    width: 20%;
    // text-align: center;
  }
}
.info-view {
  margin-top: 20px;
}
.agree {
  padding: 30px;
  border-radius: 5px;
  border: 1px solid #979797;
  margin-top: 20px;
  line-height: 30px;
}
.box-agree {
  margin-top: 20px;
  ::v-deep .el-checkbox__inner {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  ::v-deep.el-checkbox__inner::after {
    height: 10px;
    left: 7px;
    top: 2px;
  }
}
.text-red {
  font-size: 14px;
  color: #ff0101;
}
.next {
  position: absolute;
  bottom: 100px;
  left: calc(50% - 30px);
}
.success {
  line-height: 80px;
  color: #81d8d0;
  font-size: 20px;
}
</style>
